export enum AvatarBackgroundColor {
  Accent1 = 'Accent1',
  Accent2 = 'Accent2',
  Accent3 = 'Accent3',
  Accent4 = 'Accent4',
  Accent5 = 'Accent5',
  Accent6 = 'Accent6',
  Accent7 = 'Accent7',
  Accent8 = 'Accent8',
  Accent9 = 'Accent9',
  Accent10 = 'Accent10',
  Placeholder = 'Placeholder'
}
