/* eslint-disable camelcase */

/*
 * ### WARNING ###
 * This is an autogenerated file, do not change manually.
 * In order to recreate this file, please run 'node packages/base/scripts/cssVariables/parse.js'
 */

export const ThemingParameters = {
  sapBrandColor: 'var(--sapBrandColor)',
  sapHighlightColor: 'var(--sapHighlightColor)',
  sapBaseColor: 'var(--sapBaseColor)',
  sapShellColor: 'var(--sapShellColor)',
  sapBackgroundColor: 'var(--sapBackgroundColor)',
  sapFontFamily: 'var(--sapFontFamily)',
  sapFontLightFamily: 'var(--sapFontLightFamily)',
  sapFontBoldFamily: 'var(--sapFontBoldFamily)',
  sapFontHeaderFamily: 'var(--sapFontHeaderFamily)',
  sapFontSize: 'var(--sapFontSize)',
  sapFontSmallSize: 'var(--sapFontSmallSize)',
  sapFontLargeSize: 'var(--sapFontLargeSize)',
  sapFontHeader1Size: 'var(--sapFontHeader1Size)',
  sapFontHeader2Size: 'var(--sapFontHeader2Size)',
  sapFontHeader3Size: 'var(--sapFontHeader3Size)',
  sapFontHeader4Size: 'var(--sapFontHeader4Size)',
  sapFontHeader5Size: 'var(--sapFontHeader5Size)',
  sapFontHeader6Size: 'var(--sapFontHeader6Size)',
  sapTextColor: 'var(--sapTextColor)',
  sapLinkColor: 'var(--sapLinkColor)',
  sapLink_Hover_Color: 'var(--sapLink_Hover_Color)',
  sapLink_Active_Color: 'var(--sapLink_Active_Color)',
  sapLink_Visited_Color: 'var(--sapLink_Visited_Color)',
  sapLink_InvertedColor: 'var(--sapLink_InvertedColor)',
  sapCompanyLogo: 'var(--sapCompanyLogo)',
  sapBackgroundImage: 'var(--sapBackgroundImage)',
  sapBackgroundImageOpacity: 'var(--sapBackgroundImageOpacity)',
  sapBackgroundImageRepeat: 'var(--sapBackgroundImageRepeat)',
  sapSelectedColor: 'var(--sapSelectedColor)',
  sapActiveColor: 'var(--sapActiveColor)',
  sapHighlightTextColor: 'var(--sapHighlightTextColor)',
  sapTitleColor: 'var(--sapTitleColor)',
  sapNegativeColor: 'var(--sapNegativeColor)',
  sapCriticalColor: 'var(--sapCriticalColor)',
  sapPositiveColor: 'var(--sapPositiveColor)',
  sapInformativeColor: 'var(--sapInformativeColor)',
  sapNeutralColor: 'var(--sapNeutralColor)',
  sapNegativeElementColor: 'var(--sapNegativeElementColor)',
  sapCriticalElementColor: 'var(--sapCriticalElementColor)',
  sapPositiveElementColor: 'var(--sapPositiveElementColor)',
  sapInformativeElementColor: 'var(--sapInformativeElementColor)',
  sapNeutralElementColor: 'var(--sapNeutralElementColor)',
  sapNegativeTextColor: 'var(--sapNegativeTextColor)',
  sapPositiveTextColor: 'var(--sapPositiveTextColor)',
  sapCriticalTextColor: 'var(--sapCriticalTextColor)',
  sapInformativeTextColor: 'var(--sapInformativeTextColor)',
  sapNeutralTextColor: 'var(--sapNeutralTextColor)',
  sapNeutralBorderColor: 'var(--sapNeutralBorderColor)',
  sapErrorColor: 'var(--sapErrorColor)',
  sapErrorBorderColor: 'var(--sapErrorBorderColor)',
  sapWarningColor: 'var(--sapWarningColor)',
  sapWarningBorderColor: 'var(--sapWarningBorderColor)',
  sapSuccessColor: 'var(--sapSuccessColor)',
  sapSuccessBorderColor: 'var(--sapSuccessBorderColor)',
  sapInformationColor: 'var(--sapInformationColor)',
  sapInformationBorderColor: 'var(--sapInformationBorderColor)',
  sapErrorBackground: 'var(--sapErrorBackground)',
  sapWarningBackground: 'var(--sapWarningBackground)',
  sapSuccessBackground: 'var(--sapSuccessBackground)',
  sapInformationBackground: 'var(--sapInformationBackground)',
  sapNeutralBackground: 'var(--sapNeutralBackground)',
  sapIndicationColor_1: 'var(--sapIndicationColor_1)',
  sapIndicationColor_2: 'var(--sapIndicationColor_2)',
  sapIndicationColor_3: 'var(--sapIndicationColor_3)',
  sapIndicationColor_4: 'var(--sapIndicationColor_4)',
  sapIndicationColor_5: 'var(--sapIndicationColor_5)',
  sapIndicationColor_6: 'var(--sapIndicationColor_6)',
  sapIndicationColor_7: 'var(--sapIndicationColor_7)',
  sapIndicationColor_8: 'var(--sapIndicationColor_8)',
  sapElement_LineHeight: 'var(--sapElement_LineHeight)',
  sapElement_Height: 'var(--sapElement_Height)',
  sapElement_BorderWidth: 'var(--sapElement_BorderWidth)',
  sapElement_BorderCornerRadius: 'var(--sapElement_BorderCornerRadius)',
  sapElement_Compact_LineHeight: 'var(--sapElement_Compact_LineHeight)',
  sapElement_Compact_Height: 'var(--sapElement_Compact_Height)',
  sapElement_Condensed_LineHeight: 'var(--sapElement_Condensed_LineHeight)',
  sapElement_Condensed_Height: 'var(--sapElement_Condensed_Height)',
  sapContent_LineHeight: 'var(--sapContent_LineHeight)',
  sapContent_IconHeight: 'var(--sapContent_IconHeight)',
  sapContent_IconColor: 'var(--sapContent_IconColor)',
  sapContent_ContrastIconColor: 'var(--sapContent_ContrastIconColor)',
  sapContent_NonInteractiveIconColor: 'var(--sapContent_NonInteractiveIconColor)',
  sapContent_MarkerIconColor: 'var(--sapContent_MarkerIconColor)',
  sapContent_MarkerTextColor: 'var(--sapContent_MarkerTextColor)',
  sapContent_ImagePlaceholderBackground: 'var(--sapContent_ImagePlaceholderBackground)',
  sapContent_ImagePlaceholderForegroundColor: 'var(--sapContent_ImagePlaceholderForegroundColor)',
  sapContent_RatedColor: 'var(--sapContent_RatedColor)',
  sapContent_UnratedColor: 'var(--sapContent_UnratedColor)',
  sapContent_FocusColor: 'var(--sapContent_FocusColor)',
  sapContent_FocusStyle: 'var(--sapContent_FocusStyle)',
  sapContent_FocusWidth: 'var(--sapContent_FocusWidth)',
  sapContent_ContrastFocusColor: 'var(--sapContent_ContrastFocusColor)',
  sapContent_ShadowColor: 'var(--sapContent_ShadowColor)',
  sapContent_ContrastShadowColor: 'var(--sapContent_ContrastShadowColor)',
  sapContent_Shadow0: 'var(--sapContent_Shadow0)',
  sapContent_Shadow1: 'var(--sapContent_Shadow1)',
  sapContent_Shadow2: 'var(--sapContent_Shadow2)',
  sapContent_Shadow3: 'var(--sapContent_Shadow3)',
  sapContent_TextShadow: 'var(--sapContent_TextShadow)',
  sapContent_HeaderShadow: 'var(--sapContent_HeaderShadow)',
  sapContent_SearchHighlightColor: 'var(--sapContent_SearchHighlightColor)',
  sapContent_HelpColor: 'var(--sapContent_HelpColor)',
  sapContent_LabelColor: 'var(--sapContent_LabelColor)',
  sapContent_MonospaceFontFamily: 'var(--sapContent_MonospaceFontFamily)',
  sapContent_DisabledTextColor: 'var(--sapContent_DisabledTextColor)',
  sapContent_DisabledOpacity: 'var(--sapContent_DisabledOpacity)',
  sapContent_ContrastTextThreshold: 'var(--sapContent_ContrastTextThreshold)',
  sapContent_ContrastTextColor: 'var(--sapContent_ContrastTextColor)',
  sapContent_ForegroundColor: 'var(--sapContent_ForegroundColor)',
  sapContent_ForegroundBorderColor: 'var(--sapContent_ForegroundBorderColor)',
  sapContent_ForegroundTextColor: 'var(--sapContent_ForegroundTextColor)',
  sapContent_BadgeBackground: 'var(--sapContent_BadgeBackground)',
  sapContent_BadgeTextColor: 'var(--sapContent_BadgeTextColor)',
  sapContent_Placeholderloading_Background: 'var(--sapContent_Placeholderloading_Background)',
  sapContent_Placeholderloading_Gradient: 'var(--sapContent_Placeholderloading_Gradient)',
  sapContent_DragAndDropActiveColor: 'var(--sapContent_DragAndDropActiveColor)',
  sapContent_Selected_Background: 'var(--sapContent_Selected_Background)',
  sapContent_Selected_TextColor: 'var(--sapContent_Selected_TextColor)',
  sapContent_Selected_Hover_Background: 'var(--sapContent_Selected_Hover_Background)',
  sapContent_Illustrative_Color1: 'var(--sapContent_Illustrative_Color1)',
  sapContent_Illustrative_Color2: 'var(--sapContent_Illustrative_Color2)',
  sapContent_Illustrative_Color3: 'var(--sapContent_Illustrative_Color3)',
  sapContent_Illustrative_Color4: 'var(--sapContent_Illustrative_Color4)',
  sapContent_Illustrative_Color5: 'var(--sapContent_Illustrative_Color5)',
  sapContent_Illustrative_Color6: 'var(--sapContent_Illustrative_Color6)',
  sapContent_Illustrative_Color7: 'var(--sapContent_Illustrative_Color7)',
  sapContent_Illustrative_Color8: 'var(--sapContent_Illustrative_Color8)',
  sapShell_Background: 'var(--sapShell_Background)',
  sapShell_BackgroundImage: 'var(--sapShell_BackgroundImage)',
  sapShell_BackgroundGradient: 'var(--sapShell_BackgroundGradient)',
  sapShell_BackgroundImageOpacity: 'var(--sapShell_BackgroundImageOpacity)',
  sapShell_BackgroundImageRepeat: 'var(--sapShell_BackgroundImageRepeat)',
  sapShell_BorderColor: 'var(--sapShell_BorderColor)',
  sapShell_TextColor: 'var(--sapShell_TextColor)',
  sapShell_InteractiveTextColor: 'var(--sapShell_InteractiveTextColor)',
  sapShell_InteractiveBorderColor: 'var(--sapShell_InteractiveBorderColor)',
  sapShell_GroupTitleTextColor: 'var(--sapShell_GroupTitleTextColor)',
  sapShell_Hover_Background: 'var(--sapShell_Hover_Background)',
  sapShell_Active_Background: 'var(--sapShell_Active_Background)',
  sapShell_Active_TextColor: 'var(--sapShell_Active_TextColor)',
  sapShell_Selected_Background: 'var(--sapShell_Selected_Background)',
  sapShell_Selected_TextColor: 'var(--sapShell_Selected_TextColor)',
  sapShell_Selected_Hover_Background: 'var(--sapShell_Selected_Hover_Background)',
  sapShell_Favicon: 'var(--sapShell_Favicon)',
  sapShell_Navigation_Background: 'var(--sapShell_Navigation_Background)',
  sapShell_Navigation_SelectedColor: 'var(--sapShell_Navigation_SelectedColor)',
  sapShell_Navigation_Selected_TextColor: 'var(--sapShell_Navigation_Selected_TextColor)',
  sapShell_Navigation_TextColor: 'var(--sapShell_Navigation_TextColor)',
  sapShell_Shadow: 'var(--sapShell_Shadow)',
  sapButton_BorderWidth: 'var(--sapButton_BorderWidth)',
  sapButton_BorderCornerRadius: 'var(--sapButton_BorderCornerRadius)',
  sapButton_Background: 'var(--sapButton_Background)',
  sapButton_BorderColor: 'var(--sapButton_BorderColor)',
  sapButton_TextColor: 'var(--sapButton_TextColor)',
  sapButton_Hover_Background: 'var(--sapButton_Hover_Background)',
  sapButton_Hover_BorderColor: 'var(--sapButton_Hover_BorderColor)',
  sapButton_Hover_TextColor: 'var(--sapButton_Hover_TextColor)',
  sapButton_IconColor: 'var(--sapButton_IconColor)',
  sapButton_Active_Background: 'var(--sapButton_Active_Background)',
  sapButton_Active_BorderColor: 'var(--sapButton_Active_BorderColor)',
  sapButton_Active_TextColor: 'var(--sapButton_Active_TextColor)',
  sapButton_Emphasized_Background: 'var(--sapButton_Emphasized_Background)',
  sapButton_Emphasized_BorderColor: 'var(--sapButton_Emphasized_BorderColor)',
  sapButton_Emphasized_TextColor: 'var(--sapButton_Emphasized_TextColor)',
  sapButton_Emphasized_Hover_Background: 'var(--sapButton_Emphasized_Hover_Background)',
  sapButton_Emphasized_Hover_BorderColor: 'var(--sapButton_Emphasized_Hover_BorderColor)',
  sapButton_Emphasized_Hover_TextColor: 'var(--sapButton_Emphasized_Hover_TextColor)',
  sapButton_Emphasized_Active_Background: 'var(--sapButton_Emphasized_Active_Background)',
  sapButton_Emphasized_Active_BorderColor: 'var(--sapButton_Emphasized_Active_BorderColor)',
  sapButton_Emphasized_TextShadow: 'var(--sapButton_Emphasized_TextShadow)',
  sapButton_Accept_Background: 'var(--sapButton_Accept_Background)',
  sapButton_Accept_BorderColor: 'var(--sapButton_Accept_BorderColor)',
  sapButton_Accept_Hover_Background: 'var(--sapButton_Accept_Hover_Background)',
  sapButton_Accept_Hover_BorderColor: 'var(--sapButton_Accept_Hover_BorderColor)',
  sapButton_Accept_Hover_TextColor: 'var(--sapButton_Accept_Hover_TextColor)',
  sapButton_Accept_Active_Background: 'var(--sapButton_Accept_Active_Background)',
  sapButton_Accept_Active_BorderColor: 'var(--sapButton_Accept_Active_BorderColor)',
  sapButton_Accept_TextColor: 'var(--sapButton_Accept_TextColor)',
  sapButton_Accept_Selected_Background: 'var(--sapButton_Accept_Selected_Background)',
  sapButton_Accept_Selected_BorderColor: 'var(--sapButton_Accept_Selected_BorderColor)',
  sapButton_Accept_Selected_TextColor: 'var(--sapButton_Accept_Selected_TextColor)',
  sapButton_Accept_Selected_Hover_Background: 'var(--sapButton_Accept_Selected_Hover_Background)',
  sapButton_Accept_Selected_Hover_BorderColor: 'var(--sapButton_Accept_Selected_Hover_BorderColor)',
  sapButton_Reject_Background: 'var(--sapButton_Reject_Background)',
  sapButton_Reject_BorderColor: 'var(--sapButton_Reject_BorderColor)',
  sapButton_Reject_Hover_Background: 'var(--sapButton_Reject_Hover_Background)',
  sapButton_Reject_Hover_BorderColor: 'var(--sapButton_Reject_Hover_BorderColor)',
  sapButton_Reject_Hover_TextColor: 'var(--sapButton_Reject_Hover_TextColor)',
  sapButton_Reject_Active_Background: 'var(--sapButton_Reject_Active_Background)',
  sapButton_Reject_Active_BorderColor: 'var(--sapButton_Reject_Active_BorderColor)',
  sapButton_Reject_TextColor: 'var(--sapButton_Reject_TextColor)',
  sapButton_Reject_Selected_Background: 'var(--sapButton_Reject_Selected_Background)',
  sapButton_Reject_Selected_BorderColor: 'var(--sapButton_Reject_Selected_BorderColor)',
  sapButton_Reject_Selected_TextColor: 'var(--sapButton_Reject_Selected_TextColor)',
  sapButton_Reject_Selected_Hover_Background: 'var(--sapButton_Reject_Selected_Hover_Background)',
  sapButton_Reject_Selected_Hover_BorderColor: 'var(--sapButton_Reject_Selected_Hover_BorderColor)',
  sapButton_Lite_Background: 'var(--sapButton_Lite_Background)',
  sapButton_Lite_BorderColor: 'var(--sapButton_Lite_BorderColor)',
  sapButton_Lite_TextColor: 'var(--sapButton_Lite_TextColor)',
  sapButton_Lite_Hover_Background: 'var(--sapButton_Lite_Hover_Background)',
  sapButton_Lite_Hover_BorderColor: 'var(--sapButton_Lite_Hover_BorderColor)',
  sapButton_Lite_Hover_TextColor: 'var(--sapButton_Lite_Hover_TextColor)',
  sapButton_Lite_Active_Background: 'var(--sapButton_Lite_Active_Background)',
  sapButton_Lite_Active_BorderColor: 'var(--sapButton_Lite_Active_BorderColor)',
  sapButton_Selected_Background: 'var(--sapButton_Selected_Background)',
  sapButton_Selected_BorderColor: 'var(--sapButton_Selected_BorderColor)',
  sapButton_Selected_TextColor: 'var(--sapButton_Selected_TextColor)',
  sapButton_Selected_Hover_Background: 'var(--sapButton_Selected_Hover_Background)',
  sapButton_Selected_Hover_BorderColor: 'var(--sapButton_Selected_Hover_BorderColor)',
  sapButton_Attention_Background: 'var(--sapButton_Attention_Background)',
  sapButton_Attention_BorderColor: 'var(--sapButton_Attention_BorderColor)',
  sapButton_Attention_TextColor: 'var(--sapButton_Attention_TextColor)',
  sapButton_Attention_Hover_Background: 'var(--sapButton_Attention_Hover_Background)',
  sapButton_Attention_Hover_BorderColor: 'var(--sapButton_Attention_Hover_BorderColor)',
  sapButton_Attention_Hover_TextColor: 'var(--sapButton_Attention_Hover_TextColor)',
  sapButton_Attention_Active_Background: 'var(--sapButton_Attention_Active_Background)',
  sapButton_Attention_Active_BorderColor: 'var(--sapButton_Attention_Active_BorderColor)',
  sapButton_Attention_Selected_Background: 'var(--sapButton_Attention_Selected_Background)',
  sapButton_Attention_Selected_BorderColor: 'var(--sapButton_Attention_Selected_BorderColor)',
  sapButton_Attention_Selected_TextColor: 'var(--sapButton_Attention_Selected_TextColor)',
  sapButton_Attention_Selected_Hover_Background: 'var(--sapButton_Attention_Selected_Hover_Background)',
  sapButton_Attention_Selected_Hover_BorderColor: 'var(--sapButton_Attention_Selected_Hover_BorderColor)',
  sapButton_Negative_Background: 'var(--sapButton_Negative_Background)',
  sapButton_Negative_BorderColor: 'var(--sapButton_Negative_BorderColor)',
  sapButton_Negative_TextColor: 'var(--sapButton_Negative_TextColor)',
  sapButton_Negative_Hover_Background: 'var(--sapButton_Negative_Hover_Background)',
  sapButton_Negative_Hover_BorderColor: 'var(--sapButton_Negative_Hover_BorderColor)',
  sapButton_Negative_Hover_TextColor: 'var(--sapButton_Negative_Hover_TextColor)',
  sapButton_Negative_Active_Background: 'var(--sapButton_Negative_Active_Background)',
  sapButton_Negative_Active_BorderColor: 'var(--sapButton_Negative_Active_BorderColor)',
  sapButton_Critical_Background: 'var(--sapButton_Critical_Background)',
  sapButton_Critical_BorderColor: 'var(--sapButton_Critical_BorderColor)',
  sapButton_Critical_TextColor: 'var(--sapButton_Critical_TextColor)',
  sapButton_Critical_Hover_Background: 'var(--sapButton_Critical_Hover_Background)',
  sapButton_Critical_Hover_BorderColor: 'var(--sapButton_Critical_Hover_BorderColor)',
  sapButton_Critical_Hover_TextColor: 'var(--sapButton_Critical_Hover_TextColor)',
  sapButton_Critical_Active_Background: 'var(--sapButton_Critical_Active_Background)',
  sapButton_Critical_Active_BorderColor: 'var(--sapButton_Critical_Active_BorderColor)',
  sapButton_Success_Background: 'var(--sapButton_Success_Background)',
  sapButton_Success_BorderColor: 'var(--sapButton_Success_BorderColor)',
  sapButton_Success_TextColor: 'var(--sapButton_Success_TextColor)',
  sapButton_Success_Hover_Background: 'var(--sapButton_Success_Hover_Background)',
  sapButton_Success_Hover_BorderColor: 'var(--sapButton_Success_Hover_BorderColor)',
  sapButton_Success_Hover_TextColor: 'var(--sapButton_Success_Hover_TextColor)',
  sapButton_Success_Active_Background: 'var(--sapButton_Success_Active_Background)',
  sapButton_Success_Active_BorderColor: 'var(--sapButton_Success_Active_BorderColor)',
  sapButton_Neutral_Background: 'var(--sapButton_Neutral_Background)',
  sapButton_Neutral_BorderColor: 'var(--sapButton_Neutral_BorderColor)',
  sapButton_Neutral_TextColor: 'var(--sapButton_Neutral_TextColor)',
  sapButton_Neutral_Hover_Background: 'var(--sapButton_Neutral_Hover_Background)',
  sapButton_Neutral_Hover_BorderColor: 'var(--sapButton_Neutral_Hover_BorderColor)',
  sapButton_Neutral_Hover_TextColor: 'var(--sapButton_Neutral_Hover_TextColor)',
  sapButton_Neutral_Active_Background: 'var(--sapButton_Neutral_Active_Background)',
  sapButton_Neutral_Active_BorderColor: 'var(--sapButton_Neutral_Active_BorderColor)',
  sapButton_Track_Selected_Background: 'var(--sapButton_Track_Selected_Background)',
  sapButton_Track_Selected_TextColor: 'var(--sapButton_Track_Selected_TextColor)',
  sapButton_Track_Background: 'var(--sapButton_Track_Background)',
  sapButton_Track_TextColor: 'var(--sapButton_Track_TextColor)',
  sapButton_TokenBackground: 'var(--sapButton_TokenBackground)',
  sapButton_TokenBorderColor: 'var(--sapButton_TokenBorderColor)',
  sapField_Background: 'var(--sapField_Background)',
  sapField_TextColor: 'var(--sapField_TextColor)',
  sapField_PlaceholderTextColor: 'var(--sapField_PlaceholderTextColor)',
  sapField_BorderColor: 'var(--sapField_BorderColor)',
  sapField_HelpBackground: 'var(--sapField_HelpBackground)',
  sapField_BorderWidth: 'var(--sapField_BorderWidth)',
  sapField_BorderCornerRadius: 'var(--sapField_BorderCornerRadius)',
  sapField_Hover_Background: 'var(--sapField_Hover_Background)',
  sapField_Hover_BorderColor: 'var(--sapField_Hover_BorderColor)',
  sapField_Hover_HelpBackground: 'var(--sapField_Hover_HelpBackground)',
  sapField_Active_BorderColor: 'var(--sapField_Active_BorderColor)',
  sapField_Focus_Background: 'var(--sapField_Focus_Background)',
  sapField_Focus_BorderColor: 'var(--sapField_Focus_BorderColor)',
  sapField_Focus_HelpBackground: 'var(--sapField_Focus_HelpBackground)',
  sapField_ReadOnly_Background: 'var(--sapField_ReadOnly_Background)',
  sapField_ReadOnly_BorderColor: 'var(--sapField_ReadOnly_BorderColor)',
  sapField_ReadOnly_HelpBackground: 'var(--sapField_ReadOnly_HelpBackground)',
  sapField_RequiredColor: 'var(--sapField_RequiredColor)',
  sapField_InvalidColor: 'var(--sapField_InvalidColor)',
  sapField_InvalidBackground: 'var(--sapField_InvalidBackground)',
  sapField_WarningColor: 'var(--sapField_WarningColor)',
  sapField_WarningBackground: 'var(--sapField_WarningBackground)',
  sapField_SuccessColor: 'var(--sapField_SuccessColor)',
  sapField_SuccessBackground: 'var(--sapField_SuccessBackground)',
  sapField_InformationColor: 'var(--sapField_InformationColor)',
  sapField_InformationBackground: 'var(--sapField_InformationBackground)',
  sapGroup_TitleBackground: 'var(--sapGroup_TitleBackground)',
  sapGroup_TitleBorderColor: 'var(--sapGroup_TitleBorderColor)',
  sapGroup_TitleTextColor: 'var(--sapGroup_TitleTextColor)',
  sapGroup_ContentBackground: 'var(--sapGroup_ContentBackground)',
  sapGroup_ContentBorderColor: 'var(--sapGroup_ContentBorderColor)',
  sapGroup_BorderWidth: 'var(--sapGroup_BorderWidth)',
  sapGroup_BorderCornerRadius: 'var(--sapGroup_BorderCornerRadius)',
  sapGroup_FooterBackground: 'var(--sapGroup_FooterBackground)',
  sapToolbar_Background: 'var(--sapToolbar_Background)',
  sapToolbar_SeparatorColor: 'var(--sapToolbar_SeparatorColor)',
  sapList_HeaderBackground: 'var(--sapList_HeaderBackground)',
  sapList_HeaderBorderColor: 'var(--sapList_HeaderBorderColor)',
  sapList_HeaderTextColor: 'var(--sapList_HeaderTextColor)',
  sapList_BorderColor: 'var(--sapList_BorderColor)',
  sapList_TextColor: 'var(--sapList_TextColor)',
  sapList_Active_TextColor: 'var(--sapList_Active_TextColor)',
  sapList_BorderWidth: 'var(--sapList_BorderWidth)',
  sapList_Active_Background: 'var(--sapList_Active_Background)',
  sapList_SelectionBackgroundColor: 'var(--sapList_SelectionBackgroundColor)',
  sapList_SelectionBorderColor: 'var(--sapList_SelectionBorderColor)',
  sapList_Hover_SelectionBackground: 'var(--sapList_Hover_SelectionBackground)',
  sapList_Background: 'var(--sapList_Background)',
  sapList_Hover_Background: 'var(--sapList_Hover_Background)',
  sapList_AlternatingBackground: 'var(--sapList_AlternatingBackground)',
  sapList_GroupHeaderBackground: 'var(--sapList_GroupHeaderBackground)',
  sapList_GroupHeaderBorderColor: 'var(--sapList_GroupHeaderBorderColor)',
  sapList_GroupHeaderTextColor: 'var(--sapList_GroupHeaderTextColor)',
  sapList_FooterBackground: 'var(--sapList_FooterBackground)',
  sapList_FooterTextColor: 'var(--sapList_FooterTextColor)',
  sapList_TableGroupHeaderBackground: 'var(--sapList_TableGroupHeaderBackground)',
  sapList_TableGroupHeaderBorderColor: 'var(--sapList_TableGroupHeaderBorderColor)',
  sapList_TableGroupHeaderTextColor: 'var(--sapList_TableGroupHeaderTextColor)',
  sapList_TableFooterBorder: 'var(--sapList_TableFooterBorder)',
  sapList_TableFixedBorderColor: 'var(--sapList_TableFixedBorderColor)',
  sapScrollBar_FaceColor: 'var(--sapScrollBar_FaceColor)',
  sapScrollBar_TrackColor: 'var(--sapScrollBar_TrackColor)',
  sapScrollBar_BorderColor: 'var(--sapScrollBar_BorderColor)',
  sapScrollBar_SymbolColor: 'var(--sapScrollBar_SymbolColor)',
  sapScrollBar_Dimension: 'var(--sapScrollBar_Dimension)',
  sapScrollBar_Hover_FaceColor: 'var(--sapScrollBar_Hover_FaceColor)',
  sapPageHeader_Background: 'var(--sapPageHeader_Background)',
  sapPageHeader_BorderColor: 'var(--sapPageHeader_BorderColor)',
  sapPageHeader_TextColor: 'var(--sapPageHeader_TextColor)',
  sapPageFooter_Background: 'var(--sapPageFooter_Background)',
  sapPageFooter_BorderColor: 'var(--sapPageFooter_BorderColor)',
  sapPageFooter_TextColor: 'var(--sapPageFooter_TextColor)',
  sapInfobar_Background: 'var(--sapInfobar_Background)',
  sapInfobar_Hover_Background: 'var(--sapInfobar_Hover_Background)',
  sapInfobar_Active_Background: 'var(--sapInfobar_Active_Background)',
  sapObjectHeader_Background: 'var(--sapObjectHeader_Background)',
  sapObjectHeader_BorderColor: 'var(--sapObjectHeader_BorderColor)',
  sapBlockLayer_Background: 'var(--sapBlockLayer_Background)',
  sapTile_Background: 'var(--sapTile_Background)',
  sapTile_Hover_Background: 'var(--sapTile_Hover_Background)',
  sapTile_Active_Background: 'var(--sapTile_Active_Background)',
  sapTile_BorderColor: 'var(--sapTile_BorderColor)',
  sapTile_TitleTextColor: 'var(--sapTile_TitleTextColor)',
  sapTile_TextColor: 'var(--sapTile_TextColor)',
  sapTile_IconColor: 'var(--sapTile_IconColor)',
  sapTile_SeparatorColor: 'var(--sapTile_SeparatorColor)',
  sapAccentColor1: 'var(--sapAccentColor1)',
  sapAccentColor2: 'var(--sapAccentColor2)',
  sapAccentColor3: 'var(--sapAccentColor3)',
  sapAccentColor4: 'var(--sapAccentColor4)',
  sapAccentColor5: 'var(--sapAccentColor5)',
  sapAccentColor6: 'var(--sapAccentColor6)',
  sapAccentColor7: 'var(--sapAccentColor7)',
  sapAccentColor8: 'var(--sapAccentColor8)',
  sapAccentColor9: 'var(--sapAccentColor9)',
  sapAccentColor10: 'var(--sapAccentColor10)',
  sapLegend_WorkingBackground: 'var(--sapLegend_WorkingBackground)',
  sapLegend_NonWorkingBackground: 'var(--sapLegend_NonWorkingBackground)',
  sapLegend_CurrentDateTime: 'var(--sapLegend_CurrentDateTime)',
  sapLegendColor1: 'var(--sapLegendColor1)',
  sapLegendColor2: 'var(--sapLegendColor2)',
  sapLegendColor3: 'var(--sapLegendColor3)',
  sapLegendColor4: 'var(--sapLegendColor4)',
  sapLegendColor5: 'var(--sapLegendColor5)',
  sapLegendColor6: 'var(--sapLegendColor6)',
  sapLegendColor7: 'var(--sapLegendColor7)',
  sapLegendColor8: 'var(--sapLegendColor8)',
  sapLegendColor9: 'var(--sapLegendColor9)',
  sapLegendColor10: 'var(--sapLegendColor10)',
  sapLegendColor11: 'var(--sapLegendColor11)',
  sapLegendColor12: 'var(--sapLegendColor12)',
  sapLegendColor13: 'var(--sapLegendColor13)',
  sapLegendColor14: 'var(--sapLegendColor14)',
  sapLegendColor15: 'var(--sapLegendColor15)',
  sapLegendColor16: 'var(--sapLegendColor16)',
  sapLegendColor17: 'var(--sapLegendColor17)',
  sapLegendColor18: 'var(--sapLegendColor18)',
  sapLegendColor19: 'var(--sapLegendColor19)',
  sapLegendColor20: 'var(--sapLegendColor20)',
  sapLegendBackgroundColor1: 'var(--sapLegendBackgroundColor1)',
  sapLegendBackgroundColor2: 'var(--sapLegendBackgroundColor2)',
  sapLegendBackgroundColor3: 'var(--sapLegendBackgroundColor3)',
  sapLegendBackgroundColor4: 'var(--sapLegendBackgroundColor4)',
  sapLegendBackgroundColor5: 'var(--sapLegendBackgroundColor5)',
  sapLegendBackgroundColor6: 'var(--sapLegendBackgroundColor6)',
  sapLegendBackgroundColor7: 'var(--sapLegendBackgroundColor7)',
  sapLegendBackgroundColor8: 'var(--sapLegendBackgroundColor8)',
  sapLegendBackgroundColor9: 'var(--sapLegendBackgroundColor9)',
  sapLegendBackgroundColor10: 'var(--sapLegendBackgroundColor10)',
  sapLegendBackgroundColor11: 'var(--sapLegendBackgroundColor11)',
  sapLegendBackgroundColor12: 'var(--sapLegendBackgroundColor12)',
  sapLegendBackgroundColor13: 'var(--sapLegendBackgroundColor13)',
  sapLegendBackgroundColor14: 'var(--sapLegendBackgroundColor14)',
  sapLegendBackgroundColor15: 'var(--sapLegendBackgroundColor15)',
  sapLegendBackgroundColor16: 'var(--sapLegendBackgroundColor16)',
  sapLegendBackgroundColor17: 'var(--sapLegendBackgroundColor17)',
  sapLegendBackgroundColor18: 'var(--sapLegendBackgroundColor18)',
  sapLegendBackgroundColor19: 'var(--sapLegendBackgroundColor19)',
  sapLegendBackgroundColor20: 'var(--sapLegendBackgroundColor20)',
  sapChart_OrderedColor_1: 'var(--sapChart_OrderedColor_1)',
  sapChart_OrderedColor_2: 'var(--sapChart_OrderedColor_2)',
  sapChart_OrderedColor_3: 'var(--sapChart_OrderedColor_3)',
  sapChart_OrderedColor_4: 'var(--sapChart_OrderedColor_4)',
  sapChart_OrderedColor_5: 'var(--sapChart_OrderedColor_5)',
  sapChart_OrderedColor_6: 'var(--sapChart_OrderedColor_6)',
  sapChart_OrderedColor_7: 'var(--sapChart_OrderedColor_7)',
  sapChart_OrderedColor_8: 'var(--sapChart_OrderedColor_8)',
  sapChart_OrderedColor_9: 'var(--sapChart_OrderedColor_9)',
  sapChart_OrderedColor_10: 'var(--sapChart_OrderedColor_10)',
  sapChart_OrderedColor_11: 'var(--sapChart_OrderedColor_11)',
  sapChart_Bad: 'var(--sapChart_Bad)',
  sapChart_Critical: 'var(--sapChart_Critical)',
  sapChart_Good: 'var(--sapChart_Good)',
  sapChart_Neutral: 'var(--sapChart_Neutral)',
  sapChart_Sequence_1: 'var(--sapChart_Sequence_1)',
  sapChart_Sequence_2: 'var(--sapChart_Sequence_2)',
  sapChart_Sequence_3: 'var(--sapChart_Sequence_3)',
  sapChart_Sequence_4: 'var(--sapChart_Sequence_4)',
  sapChart_Sequence_5: 'var(--sapChart_Sequence_5)',
  sapChart_Sequence_6: 'var(--sapChart_Sequence_6)',
  sapChart_Sequence_7: 'var(--sapChart_Sequence_7)',
  sapChart_Sequence_8: 'var(--sapChart_Sequence_8)',
  sapChart_Sequence_9: 'var(--sapChart_Sequence_9)',
  sapChart_Sequence_10: 'var(--sapChart_Sequence_10)',
  sapChart_Sequence_11: 'var(--sapChart_Sequence_11)',
  sapChart_Sequence_Neutral: 'var(--sapChart_Sequence_Neutral)',
  'sapFontUrl_SAP-icons_ttf': 'var(--sapFontUrl_SAP-icons_ttf)',
  'sapFontUrl_SAP-icons_woff': 'var(--sapFontUrl_SAP-icons_woff)',
  'sapFontUrl_SAP-icons_woff2': 'var(--sapFontUrl_SAP-icons_woff2)',
  sapFontUrl_72_Regular_woff2: 'var(--sapFontUrl_72_Regular_woff2)',
  sapFontUrl_72_Regular_woff: 'var(--sapFontUrl_72_Regular_woff)',
  sapFontUrl_72_Bold_woff2: 'var(--sapFontUrl_72_Bold_woff2)',
  sapFontUrl_72_Bold_woff: 'var(--sapFontUrl_72_Bold_woff)',
  sapFontUrl_72_Light_woff2: 'var(--sapFontUrl_72_Light_woff2)',
  sapFontUrl_72_Light_woff: 'var(--sapFontUrl_72_Light_woff)',
  sapFontUrl_72_Regular_full_woff2: 'var(--sapFontUrl_72_Regular_full_woff2)',
  sapFontUrl_72_Regular_full_woff: 'var(--sapFontUrl_72_Regular_full_woff)',
  sapFontUrl_72_Bold_full_woff2: 'var(--sapFontUrl_72_Bold_full_woff2)',
  sapFontUrl_72_Bold_full_woff: 'var(--sapFontUrl_72_Bold_full_woff)',
  sapFontUrl_72_Light_full_woff2: 'var(--sapFontUrl_72_Light_full_woff2)',
  sapFontUrl_72_Light_full_woff: 'var(--sapFontUrl_72_Light_full_woff)'
};
